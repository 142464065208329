import useSWR from 'swr';
import { formatPriceData } from '@mentimeter/plans-content';
import { core } from '@mentimeter/http-clients';
import { MentiError, captureException } from '@mentimeter/errors/sentry';
import { useUserPppSplits } from '@mentimeter/checkout-data-hooks';
import { DEFAULT_PRICE, type LocalPricingT } from '@mentimeter/billing';
import { useEffect, useState } from 'react';

interface UsePricesT {
  isLoading: boolean;
  prices: LocalPricingT | undefined;
  error: Error | null;
}

export function usePrices(
  isInPppContentTreatment?: boolean,
  isPppContentTreatmentReady?: boolean,
): UsePricesT {
  const isProd = process.env.NODE_ENV === 'production';
  const { isInPppTreatment, isPppTreatmentReady } = useUserPppSplits();
  const isInPPPSplit = isInPppTreatment || isInPppContentTreatment;
  const isPPPSplitReady = isPppTreatmentReady || isPppContentTreatmentReady;
  const route = isInPPPSplit ? '/prices/self-service' : '/prices';

  // if the user has a configuration that is blocking split the loading state will never resolve
  // so we force it to be ready after 4 seconds - and show the old prices
  const [forceReady, setForceReady] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isPPPSplitReady) setForceReady(true);
    }, 4000);
    return () => clearTimeout(timeout);
  }, [isPPPSplitReady]);
  const effectiveIsReady = isPPPSplitReady || forceReady;

  const { data: prices, error } = useSWR(
    effectiveIsReady ? route : null,
    async () => {
      try {
        const { data } = await (isInPPPSplit
          ? core().pppPrices.get()
          : core().prices.get());
        return formatPriceData(data);
      } catch (error) {
        const fetchError = new MentiError('Price fetching failed', {
          feature: 'payments',
          tags: {
            pppTreatment: isPPPSplitReady ? 'ppp' : 'standard',
            environment: isProd ? 'production' : 'development',
            route,
            errorType: error instanceof Error ? error.name : 'unknown',
            errorMessage:
              error instanceof Error ? error.message : String(error),
          },
          cause: error,
        });
        captureException(fetchError);
        return DEFAULT_PRICE;
      }
    },
    {
      revalidateOnFocus: false,
      loadingTimeout: isProd ? 6000 : 30000,
      retryCount: isProd ? 2 : 1,
      dedupingInterval: 300000, // 5 minutes
      onLoadingSlow: () => {
        captureException(
          new MentiError('Price fetching took too long', {
            feature: 'payments',
            tags: {
              pppTreatment: isInPPPSplit ? 'ppp' : 'standard',
              environment: isProd ? 'production' : 'development',
              route,
              cacheHit: Boolean(prices),
              priceCurrency: prices ? JSON.stringify(prices.currency) : '',
            },
          }),
        );
      },
    },
  );

  return {
    isLoading: !prices && !error,
    prices: prices || DEFAULT_PRICE,
    error: error || null,
  };
}
