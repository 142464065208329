import React, { type ReactNode } from 'react';
import Image from 'next/image';
import { blueDark800, blueLight200, gray1200 } from '@mentimeter/ragnar-colors';
import { useMatch } from '@mentimeter/ragnar-device';
import { Box, P } from '@mentimeter/ragnar-ui';
import { H3 } from 'src/ui/typography';
import cloudflareLoader from 'imageLoader';
interface CardProps {
  text: {
    title: string;
    subtitle: string;
    mobileIcon: ReactNode;
  };
  image: {
    src: string;
    alt: string;
  };
}
const Card = ({ text, image }: CardProps) => {
  const isDesktop = useMatch({ greaterThan: 2 });

  return (
    <Box
      borderColor={blueLight200}
      borderWidth={isDesktop ? '1px' : 0}
      borderStyle="solid"
      borderRadius="16px"
      flex="1 1 33%"
      justifyContent="space-between"
      alignItems={['center', 'center', 'flex-start']}
      px="space6"
      py={['space0', 'space6']}
    >
      <Box flexDirection="row" width="100%" alignItems="center">
        {!isDesktop && (
          <Box ml="-38px" mr="space2">
            {text.mobileIcon}
          </Box>
        )}
        <H3
          fontSize={['100', '100', '28px']}
          fontWeight={isDesktop ? 'regular' : 'semiBold'}
          color={blueDark800}
          mb="space0"
        >
          {text.title}
        </H3>
      </Box>
      {isDesktop && (
        <Box my="space6">
          <Image
            loader={cloudflareLoader}
            src={image.src}
            alt={image.alt}
            width={338}
            height={270}
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </Box>
      )}
      <Box mt="space2" flex="1 1 auto">
        <P fontSize="100" color={gray1200}>
          {text.subtitle}
        </P>
      </Box>
    </Box>
  );
};

export default Card;
