/* eslint-disable menti-react/filename-convention--jsx */
import type {
  ClickableT,
  LinkT as RagnarLinkT,
  ButtonT,
} from '@mentimeter/ragnar-ui';
import {
  Button as RagnarButton,
  Clickable as RagnarClickable,
  Link as RagnarLink,
} from '@mentimeter/ragnar-ui';
// eslint-disable-next-line eslint-custom-plugin/no-next-router
import NextLink from 'next/link';
import * as React from 'react';

// This list is copied from our proxy, make sure this is in sync with: https://github.com/mentimeter/mm-go/blob/f63478c3a17b31cc53c82803d20a006fde50500c/apps/proxy/proxy.go#L173
const WWW_PREFIXES = [
  'app',
  'assets',
  'auth',
  'diagnostics',
  'embed',
  'favicon.ico',
  'join',
  'logout',
  'profanity',
  'preview',
  'powerpoint/app', // Special case for /powerpoint
  'public',
  'r',
  's',
  'upgrade',
  'checkout',
  'w',
  'welcome',
];

export const isWwwUrl = (url: string) =>
  WWW_PREFIXES.some(
    (prefix) =>
      url.startsWith(`/${prefix}/`) ||
      url === `/${prefix}` ||
      url.startsWith(`/${prefix}?`),
  );

interface GenericLinkProps extends Pick<RagnarLinkT, 'children'> {
  href?: string | undefined;
  prefetch?: boolean | null;
}

const wrapInNextLink =
  <T,>(Component: React.ComponentType<Omit<T, 'href' | 'prefetch'>>) =>
  ({ href, prefetch = null, ...props }: T & GenericLinkProps) => {
    if (!href) return <Component {...props} />;
    if (isWwwUrl(href)) return <Component href={href} {...props} />;

    return (
      <NextLink href={href} prefetch={prefetch} passHref legacyBehavior>
        <Component {...props} />
      </NextLink>
    );
  };

export const Button = wrapInNextLink<ButtonT>(RagnarButton);

export const Clickable = wrapInNextLink<ClickableT>(RagnarClickable);

export const Link = wrapInNextLink<RagnarLinkT>(RagnarLink);

export type LinkT = Parameters<typeof Link>[0];
