import * as React from 'react';
import { MentiError, captureException } from '@mentimeter/errors/sentry';
import { getUtmValues } from '@mentimeter/utm';
import { TrackingMethods } from '@mentimeter/google-tracking';
import {
  tracking,
  type SalesForceCreateLeadPayload,
} from '@mentimeter/http-clients';
import { Form, Textarea, TextInput, Select } from '@mentimeter/ragnar-form';
import {
  Box,
  CharacterCount,
  CharacterCountWrap,
  ErrorMessage,
} from '@mentimeter/ragnar-ui';
import { getSplitData } from '@mentimeter/splitio';
import { getLocalUser } from '@mentimeter/user';
import { trackUser } from '@api/tracking/client';
import { Trans, useTranslations } from '@mentimeter/i18n';
import * as Yup from 'yup';
import { trackVisitor } from 'src/trackVisitor';
import { useAppState } from 'src/appState';
import { isoCountries } from '@mentimeter/billing-address';
import { Button, Link } from '../ui/actions';
import { Meta } from '../ui/typography';
import { isSafeEmail } from '../../shared/email-unwanted';

interface PropsT {
  onHasSentForm: (data: FormValuesT) => void;
  placement: string;
}

export interface FormValuesT {
  firstName: string;
  lastName: string;
  Inquiry_Type__c: string;
  email: string;
  phoneNumber: string;
  message: string;
  Office_Location__c: string;
  Campaign_Name__c?: string;
  Marketing_medium__c?: string;
  Marketing_source__c?: string;
}

const SalesForceForm = ({ onHasSentForm, placement }: PropsT) => {
  const [tagLeadError, setTagLeadError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [focused, setFocused] = React.useState(['']);
  const t = useTranslations('common');
  const { user } = useAppState();

  const handleFocus = React.useCallback(
    (
      e: React.FocusEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >,
    ) => {
      const data = e.target.name;
      if (focused.some((c) => c === data)) return;
      TrackingMethods.trackEnterpriseForm(data, 'enterprise', placement);
      setFocused([data, ...focused]);
    },
    [focused, placement],
  );

  const [userFirstName, ...userLastNames] = user?.name
    ? user?.name.split(' ')
    : [];

  const SELECT_DEFAULT_VALUE = {
    key: 'defaultValue',
    value: '',
    label: t('enterprise_page.label_select'),
  };

  const fieldLabels = {
    firstName: t('enterprise_page.label_name'),
    lastName: t('enterprise_page.label_last_name'),
    Inquiry_Type__c: t('enterprise_page.label_inquiry'),
    email: t('enterprise_page.label_email'),
    phoneNumber: t('enterprise_page.label_phone'),
    message: t('enterprise_page.label_how'),
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(t('enterprise_page.form_validation_name'))
      .max(150, t('enterprise_page.form_validation_name_long')),
    lastName: Yup.string()
      .required(t('enterprise_page.form_validation_lastName'))
      .max(150, t('enterprise_page.form_validation_lastName_long')),
    Inquiry_Type__c: Yup.string().required(
      t('enterprise_page.form_validation_inquiry'),
    ),
    email: Yup.string()
      .email()
      .required(t('enterprise_page.form_validation_email'))
      .matches(
        /^(?!.*gmail|.*hotmail|.*yahoo|.*outlook).*$/,
        t('enterprise_page.form_validation_email2'),
      )
      .test('email', t('enterprise_page.form_validation_email2'), isSafeEmail)
      .max(150, t('enterprise_page.form_validation_email_long')),
    phoneNumber: Yup.string(),
    message: Yup.string().max(
      500,
      t('enterprise_page.form_validation_message'),
    ),
  });

  const sendLeadToSalesForce = async ({
    firstName,
    lastName,
    email,
    phoneNumber,
    Inquiry_Type__c,
    message,
    Office_Location__c,
    Campaign_Name__c,
    Marketing_medium__c,
    Marketing_source__c,
  }: FormValuesT) => {
    const payload: SalesForceCreateLeadPayload = {
      firstName,
      lastName,
      email,
      phone: phoneNumber,
      Inquiry_Type__c,
      description: message,
      source: 'Enterprise page',
      Office_Location__c,
      Campaign_Name__c,
      Marketing_medium__c,
      Marketing_source__c,
    };
    try {
      await tracking().salesForceCreateLead(payload);
    } catch (e) {
      setTagLeadError(true);
    }
  };

  const handleSubmit = async (data: FormValuesT) => {
    const user = getLocalUser();
    const country = await getSplitData(['country']);
    const isoCountry = isoCountries.find(({ key }) => country.country === key);
    const utmValues = getUtmValues().utmLast;

    const campaignName = utmValues?.['utm_campaign (last)'] || 'unknown';
    const mediumName = utmValues?.['utm_medium (last)'] || 'unknown';
    const sourceName = utmValues?.['utm_source (last)'] || 'unknown';

    const dataWithCustomFields = {
      ...data,
      Office_Location__c: isoCountry?.value || 'Unknown Country',
      Campaign_Name__c: campaignName || 'unknown',
      Marketing_medium__c: mediumName || 'unknown',
      Marketing_source__c: sourceName || 'unknown',
    };

    if (!isLoading) {
      setIsLoading(true);
      try {
        await sendLeadToSalesForce(dataWithCustomFields);
        setIsLoading(false);
        const payload = {
          event: `Requested sales contact`,
          properties: {
            email: data.email,
            page: window.location.pathname,
          },
        };

        trackVisitor(payload);
        if (user) {
          trackUser(payload);
        }

        return onHasSentForm(data);
      } catch (e) {
        setIsLoading(false);
        captureException(
          new MentiError('Error sending enterprise form', {
            cause: e,
            feature: 'acquisition-and-growth',
          }),
        );
      }
    }
  };

  // so that we send the en key to salesforce
  const selectOptionsKeys = [
    'Contact Sales',
    'Customer Support',
    'Conference Request',
    'Billing Questions',
  ];

  const selectOptions = [
    SELECT_DEFAULT_VALUE,
    ...[
      t('enterprise_page.label_inquiry_option_0'),
      t('enterprise_page.label_inquiry_option_1'),
      t('enterprise_page.label_inquiry_option_2'),
      t('enterprise_page.label_inquiry_option_3'),
    ].map((v, index) => ({
      key: selectOptionsKeys[index] || v,
      value: selectOptionsKeys[index] || v,
      label: v,
    })),
  ];

  return (
    <Form
      initialValues={{
        firstName: userFirstName || '',
        lastName: userLastNames.join(' ') || '',
        email: user?.email || '',
        phoneNumber: '',
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      width="100%"
      alignItems="center"
    >
      {tagLeadError ? (
        <ErrorMessage fontSize="125">
          {
            "Sorry, something went wrong and we weren't able to send your request! Please email "
          }
          <Link href="mailto:sales@mentimeter.com?subject=We’d like to use Mentimeter in our team!">
            sales@mentimeter.com
          </Link>
          {' directly to get in touch.'}
        </ErrorMessage>
      ) : (
        <>
          <Box width="100%">
            <Box
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              <TextInput
                key="firstName"
                label={fieldLabels['firstName']}
                name="firstName"
                width="48%"
                extend={() => ({ alignSelf: 'stretch' })}
                onFocus={handleFocus}
              />
              <TextInput
                key="lastName"
                label={fieldLabels['lastName']}
                name="lastName"
                width="48%"
                extend={() => ({ alignSelf: 'stretch' })}
                onFocus={handleFocus}
              />
            </Box>
            <Box
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              <Select
                name="Inquiry_Type__c"
                label={fieldLabels['Inquiry_Type__c']}
                width="100%"
                defaultValue={SELECT_DEFAULT_VALUE.value}
                onFocus={handleFocus}
                options={selectOptions}
              />
            </Box>
            <TextInput
              key="email"
              label={fieldLabels['email']}
              name="email"
              placeholder={t('enterprise_page.hint_email')}
              extend={() => ({ alignSelf: 'stretch' })}
              onFocus={handleFocus}
            />
            <TextInput
              key="phoneNumber"
              label={fieldLabels['phoneNumber']}
              name="phoneNumber"
              placeholder="+0 (123) 456-7890"
              hintText={t('enterprise_page.hint_phone')}
              extend={() => ({ alignSelf: 'stretch' })}
              onFocus={handleFocus}
            />
            <CharacterCountWrap width="100%" multiline mt="space4" mb="space2">
              <Textarea
                label={fieldLabels['message']}
                name="message"
                aria-describedby="message-character-counter"
                onChange={(e) => setMessage(e.target.value)}
                onFocus={handleFocus}
                rows={1}
                minHeight="initial"
                inputSize="compact"
              />
              <CharacterCount
                id="message-character-counter"
                maxLength={500}
                value={message}
                multiline
              />
            </CharacterCountWrap>
          </Box>
          <Button
            mb="space4"
            aria-label="Send"
            mt="space4"
            size="large"
            width="100%"
            disabled={isLoading}
            state={isLoading ? 'loading' : undefined}
            type="submit"
            variant="primary"
          >
            {t('enterprise_page.form_button')}
          </Button>
          <Meta textAlign="center" color="black">
            <Trans
              t={t}
              i18nKey="enterprise_page.form_footer"
              components={{
                TermsLink: <Link href="/trust/legal/terms" />,
                PoliciesLink: <Link href="/trust/legal/privacy-policy" />,
              }}
            />
          </Meta>
        </>
      )}
    </Form>
  );
};

export default SalesForceForm;
