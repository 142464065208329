'use client';
import type { Document } from '@contentful/rich-text-types';
import { gtmTrack } from '@mentimeter/google-tracking';
import {
  Box,
  Ratio,
  RATIO_LANDSCAPE,
  Text,
  ModalRoot,
  ModalTrigger,
  ModalPortal,
  Light,
  ModalOverlay,
  ScreenReaderOnly,
  ModalTitle,
  ModalDismiss,
  ModalContent,
} from '@mentimeter/ragnar-ui';
import dynamic from 'next/dynamic';
import { CrossIcon, PlaySolidIcon } from '@mentimeter/ragnar-visuals';
import { useTranslations } from '@mentimeter/i18n';
import * as React from 'react';
import { useRef, useState } from 'react';
import loadYoutubeVideo from 'src/utils/loadYoutubeVideo';
import { CaptionRenderer } from '../cms/CaptionRenderer';
import { Button, Clickable } from './actions';
import { Wide } from './layout';

// To make server component rendering on edge possible
const EmbeddedVimeo = dynamic(() => import('./EmbeddedVimeo'), { ssr: false });

export interface EmbeddedVideoT {
  url: string;
  title?: string | undefined;
}

export type VideoT = EmbeddedVideoT & {
  preview?: string | undefined;
  caption?: Document;
  faded?: boolean;
  ratio?: string;
};

const defaultVideoProps = {
  frameBorder: '0',
  allowFullScreen: true,
  width: '100%',
  height: '100%',
  sandbox: 'allow-scripts allow-same-origin',
};

interface EmbeddedYoutubeProps {
  url: string;
  onPlay: () => void;
}

const EmbeddedYoutube = ({ url, onPlay }: EmbeddedYoutubeProps) => {
  const videoRef = useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (videoRef.current) {
      loadYoutubeVideo({
        url,
        onPlay,
        container: videoRef.current,
        options: defaultVideoProps,
      });
    }
  }, [videoRef, url, onPlay]);

  return <div ref={videoRef}></div>;
};

export const EmbeddedVideo = ({ url, title }: EmbeddedVideoT) => {
  const isVimeo = url.includes('player.vimeo.com/video/');
  const isYoutube =
    url.includes('youtube.com/embed/') ||
    url.includes('youtube-nocookie.com/embed/');

  const onPlay = React.useCallback(
    () =>
      title &&
      gtmTrack({
        event: 'watchVideo',
        type: title,
        provider: isVimeo ? 'vimeo' : 'youtube',
      }),
    [title, isVimeo],
  );

  if (!isVimeo && !isYoutube) return null;

  return (
    <Ratio ratio="landscape">
      {isVimeo && <EmbeddedVimeo url={url} onPlay={onPlay} title={title} />}
      {isYoutube && <EmbeddedYoutube url={url} onPlay={onPlay} />}
    </Ratio>
  );
};

export const Video = ({
  preview,
  url,
  title,
  caption,
  faded = false,
  ratio = RATIO_LANDSCAPE,
}: VideoT) => {
  const [show, setShow] = useState(false);
  const t = useTranslations('common');

  if (preview) {
    return (
      <ModalRoot open={show} onOpenChange={setShow}>
        <ModalTrigger>
          <Box width="100%" height="100%" borderRadius={1} overflow="hidden">
            <Clickable
              aria-label={t('page_links.watch_video')}
              width="100%"
              height="100%"
              extend={({ theme }) => ({
                opacity: faded ? theme.opacity.faded : 1,
              })}
            >
              <Ratio
                customRatio={ratio}
                backgroundImage={`url(${preview}?w=800&q=100)`}
                backgroundSize="cover"
              />
            </Clickable>
            <Box
              position="absolute"
              width="100%"
              height="100%"
              top={0}
              left={0}
              justifyContent="center"
              alignItems="center"
              extend={() => ({
                pointerEvents: 'none',
              })}
            >
              <Button variant="primary" iconTrailing={PlaySolidIcon}>
                {t('page_links.watch_video')}
              </Button>
            </Box>
          </Box>
        </ModalTrigger>
        <ModalPortal>
          <Light>
            <ModalOverlay />
            <ScreenReaderOnly>
              <ModalTitle>Video: {title}</ModalTitle>
            </ScreenReaderOnly>
          </Light>
          <ModalContent
            width="95%"
            height="90%"
            alignItems="center"
            justifyContent="center"
            overflow="hidden"
            bg="transparent"
          >
            <Wide width="100%">
              <Ratio ratio="landscape">
                <EmbeddedVideo url={url} title={title} />
              </Ratio>
            </Wide>
            <Box position="absolute" top={0} right={0}>
              <ModalDismiss>
                <Clickable
                  bg="neutralWeakest"
                  borderRadius={3}
                  width="60px"
                  height="60px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CrossIcon size={3} color="text" />
                  <Text fontSize="75" color="text">
                    esc
                  </Text>
                </Clickable>
              </ModalDismiss>
            </Box>
          </ModalContent>
        </ModalPortal>
      </ModalRoot>
    );
  }

  return (
    <>
      <EmbeddedVideo url={url} title={title} />
      {caption && <CaptionRenderer document={caption} />}
    </>
  );
};
