import type { WorkspaceResponseT } from '@mentimeter/http-clients';
import { core } from '@mentimeter/http-clients';
import { useCallback } from 'react';
import useSWR, { mutate } from 'swr';
import { SSO_SIGNUPMODES } from './constants';
import type { FrontEndOnlyWorkspaceProperties, WorkspaceT } from './types';
import { camelify, snakify } from './utils';

type WorkspaceRequest = Partial<
  Omit<WorkspaceT, keyof FrontEndOnlyWorkspaceProperties>
>;

interface UseWorkspaceT {
  error: any;
  data?: WorkspaceT | undefined;
  hasWorkspace: boolean;
  isLoading: boolean;
  update: (
    data: WorkspaceRequest,
    options?: { shouldUpdateOptimistically: boolean },
  ) => Promise<WorkspaceT | undefined>;
  create: (payload: WorkspaceRequest) => Promise<number | null>;
  skip?: boolean;
}

const normalizeData = {
  stateToServer: (data: WorkspaceRequest): Partial<WorkspaceResponseT> => ({
    ...snakify(data),
  }),
  serverToState: (data: WorkspaceResponseT): WorkspaceT => ({
    ...camelify(data),
    // Frontend-only derived properties
    usesSso: SSO_SIGNUPMODES.includes(data.signup_mode),
  }),
};

const WORKSPACE_SWR_CACHE_KEY = 'workspace';
export const mutateWorkspace = () => mutate(WORKSPACE_SWR_CACHE_KEY);

// We should phase out handleErrorGlobally, allowing to override this default config until all consumers
// support handling contextual error handling
/**
 * @deprecated for workspace fetching use [./useCurrentUserWorkspace.ts](useCurrentUserWorkspace.ts) instead
 */
export const useWorkspace = ({
  handleUpdateErrorGlobally = true,
  skip = false,
} = {}): UseWorkspaceT => {
  const { data, error } = useSWR<WorkspaceT | null>(
    skip ? null : WORKSPACE_SWR_CACHE_KEY,
    async () => {
      const { data, status } = await core().workspaces.getActiveWorkspace();
      if (status === 204) {
        return null;
      }

      return normalizeData.serverToState(data);
    },
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    },
  );

  const update = useCallback(
    async (
      newData: WorkspaceRequest,
      options = { shouldUpdateOptimistically: true },
    ): Promise<WorkspaceT | undefined> => {
      if (!data) {
        /* we need the workspaceId to do the update */
        return Promise.reject(new Error('cant find workspace id'));
      }

      return mutate(
        WORKSPACE_SWR_CACHE_KEY,
        core()
          .workspaces.updateWorkspace(
            data.id,
            normalizeData.stateToServer(newData),
            {
              // @ts-expect-error-auto TS(2345) FIXME: Argument of type '{ handleErrorGlobally: boolean; ... Remove this comment to see the full error message
              handleErrorGlobally: handleUpdateErrorGlobally,
            },
          )
          .then(({ data }) => normalizeData.serverToState(data)),
        options.shouldUpdateOptimistically
          ? {
              revalidate: false,
              optimisticData: {
                ...data,
                ...newData,
              },
              rollbackOnError: true,
            }
          : { revalidate: false },
      );
    },
    [data, handleUpdateErrorGlobally],
  );

  async function create(payload: WorkspaceRequest): Promise<number | null> {
    const workspace = await core().workspaces.post(payload);
    mutate(WORKSPACE_SWR_CACHE_KEY);
    return workspace?.data.id || null;
  }

  if (error)
    return {
      data: data || undefined,
      isLoading: false,
      hasWorkspace: false,
      error,
      update,
      create,
    };
  else if (!data)
    return {
      data: undefined,
      isLoading: data === undefined && !error,
      error: null,
      hasWorkspace: false,
      update,
      create,
    };

  return {
    error,
    update,
    create,
    data: data || undefined,
    hasWorkspace: data !== null,
    isLoading: data === undefined && !error,
  };
};
