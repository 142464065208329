const normalizeSrc = (src: string) => {
  return src.startsWith('/') ? src.slice(1) : src;
};

const ORIGIN = process.env.NEXT_PUBLIC_ORIGIN;

/**
 * Cloudflare image loader https://developers.cloudflare.com/images/transform-images/integrate-with-frameworks/
 */
export default function cloudflareLoader({
  src,
  width,
  quality = 75,
  format = 'auto',
}: { src: string; width: number; quality?: number; format?: string }) {
  const params = [`width=${width}`, `f=${format}`, `quality=${quality}`];
  const paramsString = params.join(',');

  // To allow the image optmization to work even in preview we request it from the origin where the image optimization is enabled (CF setting)
  return `${ORIGIN}/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`;
}
