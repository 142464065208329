import * as React from 'react';
import { Ratio, type RatioValue } from '../ratio';
import { Box } from '../box';
export interface FrameT {
  children: React.ReactNode;
}

export const FrameBrowser = ({
  children,
  ratio = 'landscape',
  customRatio,
}: React.PropsWithChildren<FrameT & RatioValue>) => {
  return (
    <Box className="border-2 border-solid border-weaker bg rounded-lg w-full flex-auto overflow-hidden">
      <Box className="w-full h-[28px] bg-primary-weakest" />
      {customRatio ? (
        <Ratio customRatio={customRatio}>{children}</Ratio>
      ) : (
        <Ratio ratio={ratio}>{children}</Ratio>
      )}
    </Box>
  );
};
