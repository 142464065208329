import { useUserSplits, useVisitorSplits } from 'src/split-hooks';

export function useContentPppSplits() {
  const visitorSplit = useVisitorSplits(['PAY_Ppp_Rollout_Visitor']);
  const userSplit = useUserSplits(['PAY_Ppp_Rollout_User']);

  return {
    isInContentPppTreatment:
      visitorSplit.PAY_Ppp_Rollout_Visitor === 'on' ||
      userSplit.PAY_Ppp_Rollout_User === 'on',
    isInContentPppTreatmentReady: visitorSplit.isReady && userSplit.isReady,
  };
}
