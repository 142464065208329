import { Field, type FieldProps } from 'formik';
import React from 'react';
import {
  Box,
  CharacterCount,
  CharacterCountWrap,
  Label,
  Meta,
  TextareaItem,
  type TextareaItemT,
  Tooltip,
  TriggerDescriptive,
} from '@mentimeter/ragnar-ui';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { InputFeedback } from '../input-feedback';
import { getErrorMessage, getShouldShowError } from '../utils';

export interface TextareaProps extends TextareaItemT {
  label: string;
  optional?: boolean;
  description?: string;
  hintText?: React.ReactNode;
  validate?: (value: any) => void | string | Promise<any>;
  innerRef?: any;
}

export function Textarea({
  name,
  label,
  rows,
  description,
  optional = false,
  placeholder,
  validate,
  innerRef,
  hintText,
  onBlur,
  onChange,
  onFocus,
  autoComplete,
  autoFocus,
  disabled,
  maxLength,
  maxHeight,
  minHeight,
  inputSize,
  className,
  ...restOfTheTextareaProps
}: TextareaProps) {
  return (
    <Field name={name} validate={validate} innerRef={innerRef}>
      {(fieldProps: FieldProps) => {
        const shouldShowErrorMessage = getShouldShowError(fieldProps);
        return (
          <Box className="w-full">
            <Box className="flex-row items-start mb-2">
              <Box className="flex-row">
                <Label htmlFor={name} className="mb-0">
                  {label}
                  {optional ? (
                    <Meta className="text-normal leading-snug ml-1">
                      (optional)
                    </Meta>
                  ) : null}
                </Label>
              </Box>
              {description && (
                <Tooltip
                  trigger={<TriggerDescriptive />}
                  id={`${name}-label-description`}
                >
                  {description}
                </Tooltip>
              )}
            </Box>
            <CharacterCountWrap className="w-full">
              <TextareaItem
                id={name}
                aria-describedby={`input-feedback-${name} character-counter-${name}`}
                value={fieldProps.field.value}
                name={fieldProps.field.name}
                rows={rows}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                disabled={disabled}
                maxLength={maxLength}
                onFocus={onFocus}
                inputSize={inputSize}
                onBlur={(e) => {
                  fieldProps.field.onBlur(e);
                  if (onBlur) onBlur(e);
                }}
                onChange={(e) => {
                  fieldProps.field.onChange(e);
                  if (onChange) onChange(e);
                }}
                status={shouldShowErrorMessage ? 'error' : undefined}
                placeholder={placeholder}
                {...restOfTheTextareaProps}
                className={clsx([
                  'max-h-[--textarea-max-height]',
                  'min-h-[--textarea-min-height]',
                  className,
                ])}
                style={
                  {
                    '--textarea-max-height': maxHeight,
                    '--textarea-min-height': minHeight,
                  } as React.CSSProperties
                }
              />
              {maxLength !== undefined && (
                <CharacterCount
                  id={`character-counter-${name}`}
                  maxLength={maxLength}
                  value={fieldProps.field.value}
                />
              )}
            </CharacterCountWrap>
            <InputFeedback
              error={getErrorMessage(fieldProps)}
              showError={shouldShowErrorMessage}
              hint={hintText}
              alwaysTakeSpace={false}
              id={`input-feedback-${name}`}
            />
          </Box>
        );
      }}
    </Field>
  );
}
