import { useExperiment } from '@mentimeter/splitio';

export function useUserPppSplits() {
  const { isInExperiment, experimentIsReady } = useExperiment(
    'PAY_Ppp_Rollout_User',
    ['on'],
  );

  return {
    isInPppTreatment: isInExperiment,
    isPppTreatmentReady: experimentIsReady,
  };
}
