import { buildPresentationPath } from '@mentimeter/presentation-url-utils';
import ScreenshotFallbackImage from './assets/ScreenshotFallbackImage.png';

const DAYS_10 = 60 * 60 * 24 * 10;

interface GetScreenshotUrlParameters {
  height?: number;
  maxAge?: number;
  hostname?: 'www.mentimeter.com';
  presentationId: string;
  questionId?: string;
  width?: number;
}

export const getScreenshotUrl = ({
  presentationId,
  questionId,
  hostname,
  width = 622,
  height = 350,
  maxAge = DAYS_10,
}: GetScreenshotUrlParameters) => {
  if (!questionId) {
    return ScreenshotFallbackImage.src;
  }

  const path = buildPresentationPath({
    seriesId: presentationId,
    questionId,
    mode: 'screenshot-image',
  });

  const url = new URL(path, 'https://mentimeter.com');

  if (hostname) {
    url.searchParams.set('hostname', hostname);
  }

  url.searchParams.set('maxAge', maxAge.toString());
  url.searchParams.set('w', width.toString());
  url.searchParams.set('h', height.toString());

  return url.pathname + url.search;
};
