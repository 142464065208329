import { useUser } from '@mentimeter/user';
import type { PlanCategory } from '@mentimeter/http-clients';
import { core, PaymentMethodType } from '@mentimeter/http-clients';
import type { AxiosError } from 'axios';
import type { Stripe, StripeError } from '@stripe/stripe-js';
import { fullMentimeterUrl } from '@mentimeter/next-navigation';
import { useWorkspace } from '@mentimeter/workspace-data-hooks';
import {
  useInvoicePreview,
  useUpgradeOptions,
  getStripe,
  type PaymentMethodT,
  useBillingHistory,
  useSubscriptions,
  billingRules,
} from '@mentimeter/billing';
import { teamAccessLevels } from '@mentimeter/workspace-features';
import { useEffect, useState } from 'react';
import { trackUser } from '@api/tracking/client';
import type { CheckoutModalType } from '../types';

const to = <T, U = unknown>(
  p: Promise<T>,
): Promise<[U, undefined] | [null, T]> =>
  p
    .then<[null, T]>((data) => [null, data])
    .catch<[U, undefined]>((err: U) => [err, undefined]);

export interface PlanData {
  name?: string | undefined;
  category?: PlanCategory | undefined;
  licenses?: number | undefined;
  cycle: string;
  price?: string | undefined;
  billingDate?: string | undefined;
  daysUntilSubscriptionEnds?: number | undefined;
  discountPercentage?: number | undefined;
  unitPrice?: string | undefined;
  proratedDiscount?: string | undefined;
}

export const useUserPlansData = ({
  plan,
  licenses,
  isInContentPppTreatment,
  isInContentPppTreatmentReady,
}: {
  plan: PlanCategory;
  licenses?: number;
  isInContentPppTreatment: boolean;
  isInContentPppTreatmentReady: boolean;
}) => {
  const { user } = useUser();
  const { subscriptions } = useSubscriptions();
  const { licenseAmount } = billingRules(subscriptions);

  const { invoicePreview, isLoading, error } = useInvoicePreview({
    plan,
    licenses: licenses || licenseAmount || 0,
    options: { skip: !user || user?.isFreeUser, revalidateOnFocus: false },
    isInContentPppTreatment,
    isInContentPppTreatmentReady,
  });

  if (isLoading && !invoicePreview) {
    return {
      isLoading: true,
    };
  }

  if (error || !invoicePreview) {
    return {
      isLoading: false,
    };
  }

  return {
    currentPlan: {
      name: invoicePreview.currentPlanCategory,
      category: invoicePreview.currentPlanCategoryRaw,
      licenses: invoicePreview.currentPlanLicenses,
      cycle: invoicePreview.currentPlanCycle === 'year' ? 'yearly' : 'monthly',
      price: invoicePreview.currentPlanTotalPrice!,
      billingDate: invoicePreview.currentPlanBillingDate,
      unitPrice: invoicePreview.currentPlanUnitPrice!,
      daysUntilSubscriptionEnds: invoicePreview?.daysUntilSubscriptionEnds,
    },
    newPlan: {
      name: invoicePreview.newPlanCategory,
      category: invoicePreview.newPlanCategoryRaw!,
      licenses: invoicePreview.newPlanLicenses!,
      cycle: invoicePreview.newPlanCycle === 'year' ? 'yearly' : 'monthly',
      price: invoicePreview.newPlanTotalPrice!,
      billingDate: invoicePreview.newPlanBillingDate,
      discountPercentage: invoicePreview.discountPercentage,
      unitPrice: invoicePreview.newPlanUnitPrice!,
      proratedDiscount: invoicePreview.proratedDiscount,
    },
    tax: invoicePreview.tax,
    hasTax: invoicePreview.tax !== '$0.00',
    taxPercentage: invoicePreview.taxPercentage,
    subtotal: invoicePreview.subtotal,
    total: invoicePreview.total,
    progressiveDiscountAmount: invoicePreview.progressiveDiscountAmount,
    progressiveDiscountRate: invoicePreview.progressiveDiscountRate,
    prorationDate: invoicePreview.prorationDate,
    isLoading: false,
  };
};

interface Range {
  from: number;
  up_to: number;
}

export const useLicensesRange = (
  modalType: CheckoutModalType,
): undefined | Range => {
  const { isLoading, possibleUpgrades } = useUpgradeOptions();

  if (isLoading) {
    return undefined;
  }

  const findPlan =
    modalType === 'licenses'
      ? possibleUpgrades?.current_plan.plan_category
      : 'pro';

  return possibleUpgrades?.upgrade_options.find(
    (option) => option.plan_category === findPlan,
  )?.licenses;
};

export const DefaultPurchaseErrorMessage =
  'There was an error, please try again.';

export const trackSubmitting = (newPlan: PlanData, currentPlan: PlanData) => {
  trackUser({
    event: 'Clicked confirm and pay button',
    properties: {
      context: 'Checkout modal',
    },
  });
};

interface UpdateSubscriptionResponseT {
  data: {
    latest_invoice_url: string;
    payment_intent_client_secret: string;
    request_id: string;
  };
}

interface UpdateSubscriptionT {
  licenses: number;
  plan_category: PlanCategory;
  proration_date: number;
}

export const updateSubscription = (
  newData: UpdateSubscriptionT,
  isPpp: boolean,
  isInContentPppTreatment: boolean,
): Promise<[AxiosError | null, UpdateSubscriptionResponseT | undefined]> => {
  const isInSplit = isPpp || isInContentPppTreatment;

  const route = `/payment-services/subscriptions${isInSplit ? '/update' : ''}`;
  return to(core().put(route, newData));
};

const getPaymentMethodError = async (
  stripeClient: Stripe,
  payment_intent_client_secret: string,
  paymentMethod: PaymentMethodT,
): Promise<string | undefined> => {
  let error: StripeError | undefined = undefined;

  switch (paymentMethod.type) {
    case PaymentMethodType.PayPal:
      const { error: paypalError } = await stripeClient?.confirmPayPalPayment(
        payment_intent_client_secret,
        {
          return_url: fullMentimeterUrl('/app/billing'),
        },
      );
      error = paypalError;
      break;
    case PaymentMethodType.Card:
    case PaymentMethodType.Link:
      const { error: cardError } = await stripeClient?.confirmCardPayment(
        payment_intent_client_secret,
      );
      error = cardError;
      break;
  }

  return error?.message;
};

export const updateStripe = async (
  payment_intent_client_secret: string,
  paymentMethod?: PaymentMethodT,
): Promise<string | undefined> => {
  const stripeClient = await getStripe();

  if (!stripeClient) {
    return DefaultPurchaseErrorMessage;
  }

  const { paymentIntent, error } = await stripeClient.retrievePaymentIntent(
    payment_intent_client_secret,
  );

  if (error) {
    return DefaultPurchaseErrorMessage;
  }

  if (paymentIntent?.status !== 'succeeded') {
    if (paymentIntent.status === 'requires_action' && paymentMethod) {
      return getPaymentMethodError(
        stripeClient as Stripe,
        payment_intent_client_secret,
        paymentMethod,
      );
    }
    return DefaultPurchaseErrorMessage;
  }

  return undefined;
};

export const useIsUpgradeAvailable = () => {
  const { user, isLoading: isUserLoading } = useUser();
  const { data: workspace, isLoading: isWorkspaceLoading } = useWorkspace();
  const { hasUnpaidInvoice, isLoading: isLoadingInvoice } = useBillingHistory();
  const { error: upgradeNotPossible, isLoading: isUpgradeOptionsLoading } =
    useUpgradeOptions();
  const { subscriptions } = useSubscriptions();
  const { hasCurrentSubscription } = billingRules(subscriptions);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(
      isUserLoading ||
        isWorkspaceLoading ||
        isUpgradeOptionsLoading ||
        isLoadingInvoice,
    );
  }, [
    isUserLoading,
    isWorkspaceLoading,
    isUpgradeOptionsLoading,
    isLoadingInvoice,
  ]);

  const isStripe = user?.payments_provider === 'stripe';
  const isCustomSales = user?.payments_provider === 'unknown';
  const { isOwner } = teamAccessLevels(user, workspace);
  const upgradeForbidden =
    !hasCurrentSubscription && (!isStripe || isCustomSales);

  return {
    isOwner,
    upgradeNotPossible,
    hasUnpaidInvoice,
    upgradeForbidden,
    upgradeAvailable: isOwner && !upgradeForbidden && !upgradeNotPossible,
    isLoading,
  };
};
